import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { ROUTES } from 'router'
import { useCustomAnalyticsEvent } from 'hooks/useGoogleAnalytics'

interface CompanyNotFoundProps {
    setTitle: (param: string) => void
    navigateHandler: (props: string) => void
}

export const CompanyNotFoundPage = ({ setTitle, navigateHandler }: CompanyNotFoundProps) => {
    const { sendCustomEvent } = useCustomAnalyticsEvent()

    useEffect(() => {
        setTitle('We couldn’t find your company')
    }, [])

    return (
        <>
            <div className="sign-up__row">
                <p className="sign-up__text" style={{ margin: 0, lineHeight: '22px' }}>
                    Please contact your company’s portal admin to finish creating your account or{' '}
                    <Link
                        style={{ textDecoration: 'underline' }}
                        className="link"
                        to={`/${ROUTES.company.base}`}
                        onClick={() => sendCustomEvent('register_new_company_clicked')}
                    >
                        Register your Company
                    </Link>
                </p>
            </div>
        </>
    )
}
