import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { ReactComponent as ArrowLeftIcon } from '../../assets/icons/arrow-left.svg'
import OlimpLogo from '../../assets/images/new_logo.png'
import { URLs } from '../../urls'
import { toast } from 'react-toastify'
import EmailNotificationFormContainer from './EmailNotificationFormContainer'
import UnsubscribeForm from './UnsubscribeForm'
import UnsubscribeSuccess from './UnsubscribeSuccess'
import { EmailPreferencesResponse } from './interfaces'

interface IEmailNotificationSettingsPageProps {
    setTitle: (param: string) => void
    navigateHandler: (props: string) => void
    setShowProgress: (props: boolean) => void
    setShowBackButton?: any
}

export const handleSubscribe = async (
    token,
    preferences,
    successMsg,
    failedMsg,
    errorMsg,
    onSuccess = () => {},
    setIsSubmitting = (value) => {},
) => {
    setIsSubmitting(true)
    try {
        const response = await fetch(`${URLs.baseApi}/sso/email-preferences/${token}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                preferences,
            }),
        })

        if (response.ok) {
            toast(successMsg, { type: 'success' })
            onSuccess() // Notify success
        } else {
            const data = await response.json()
            toast(data.message || failedMsg, { type: 'error' })
        }
    } catch (error: any) {
        toast(error.message || errorMsg, { type: 'error' })
    } finally {
        setIsSubmitting(false)
    }
}

export const EmailNotificationSettingsPage = ({
    setTitle,
    navigateHandler,
    setShowProgress,
    setShowBackButton,
}: IEmailNotificationSettingsPageProps) => {
    const { '*': extraPath } = useParams()
    const isUnsubscribeAll = extraPath === 'unsubscribe-all'
    const [page, setPage] = useState(isUnsubscribeAll ? 2 : 1)
    const [emailPreferences, setEmailPreferences] = useState<EmailPreferencesResponse>({
        email: '',
        type: '',
        preferences: {
            chatEmailOptOut: false,
            marketingEmailOptOut: false,
            warehousingEmailOptOut: false,
        },
    })
    const url = new URL(window.location.href)
    const token = url.searchParams.get('token')
    const navigate = useNavigate()

    useEffect(() => {
        setTitle('')
        setShowBackButton(false)
        setShowProgress(false)

        // API call to fetch email preferences
        const fetchEmailPreferences = async () => {
            try {
                const response = await fetch(`${URLs.baseApi}/sso/email-preferences/${token}`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                })

                if (response.ok) {
                    const data = await response.json()
                    setEmailPreferences(data)
                } else {
                    const data = await response.json()
                    toast(data.message || 'Failed to fetch email preferences', { type: 'error' })
                }
            } catch (error: any) {
                toast(error.message || 'Error fetching email preferences', { type: 'error' })
            }
        }

        if (token) {
            fetchEmailPreferences()
        }
    }, [token])

    const handleResubscribe = () => {
        setPage(1)
        setEmailPreferences({
            ...emailPreferences,
            preferences: {
                chatEmailOptOut: false,
                marketingEmailOptOut: false,
                warehousingEmailOptOut: false,
            },
        })
        navigate(`/email-preferences/code?token=${token}`)
    }

    const renderPartPage = (param: number) => {
        switch (param) {
            case 1:
                return (
                    <EmailNotificationFormContainer
                        email={emailPreferences?.email}
                        token={token}
                        type={emailPreferences?.type}
                        preferences={emailPreferences.preferences}
                        onSuccess={() => setPage(3)}
                    />
                )
            case 2:
                return (
                    <UnsubscribeForm
                        email={emailPreferences}
                        token={token}
                        onCancel={() => setPage(1)}
                        onSuccess={() => setPage(3)}
                        type={emailPreferences?.type}
                    />
                )
            case 3:
                return (
                    <UnsubscribeSuccess
                        email={emailPreferences}
                        token={token}
                        type={emailPreferences?.type}
                        onResubscribe={handleResubscribe} // Вызов resubscribe
                    />
                )
            default:
                return null
        }
    }

    return (
        <div className="email-notification-settings">
            <div className="sign-up__back" role="button" tabIndex={-1}>
                <div
                    className="sign-up__backTitle"
                    onClick={() => {
                        navigate(-1)
                    }}
                >
                    <ArrowLeftIcon /> <span className="sign-up__backButtonText">Back</span>
                </div>
            </div>
            <div className="sign-up__header">
                <img src={OlimpLogo} className="sign-up__logo" alt="Olimp logo" />
                <h4 className="mx-auto font-semibold">Email Notification Settings</h4>
            </div>
            {renderPartPage(page)}
        </div>
    )
}
