import React, { FC, memo, ReactNode } from 'react';
import cn from 'classnames'
import './TextareaLabel.scss';

interface TextareaProps extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
    key?: string;
    label?: string;
    errorMessage?: string | ReactNode;
    value: any;
    onChange: (param?: any) => void;
    style?: React.CSSProperties;
    textareaStyle?: React.CSSProperties;
    className?: string;
    textareaClassName?: string;
    name?: string;
    disabled?: boolean;
    autoComplete?: string;
}

const TextareaLabel: FC<TextareaProps> = ({
                                              key,
                                              label,
                                              errorMessage,
                                              onChange,
                                              value,
                                              style,
                                              textareaStyle,
                                              textareaClassName,
                                              className,
                                              name,
                                              disabled = false,
                                              autoComplete = 'off',
                                              ...rest
                                          }) => {
    const id: string | number = key ?? '1';

    return (
        <div className={`textarea-label ${className}`} key={key} style={style}>
            <label className="textarea-label__label" htmlFor={id}>
                {label}
            </label>
            <textarea
                disabled={disabled}
                name={name}
                className={cn(
                    'textarea-label__textarea',
                    textareaClassName && textareaClassName,
                    errorMessage && 'error',
                )}
                value={value}
                onChange={onChange}
                style={textareaStyle}
                autoCapitalize="off"
                autoCorrect="off"
                autoComplete="new-password"
                {...rest}
            />
            {errorMessage && <p className="textarea-label__error">{errorMessage}</p>}
        </div>
    );
};

export default memo(TextareaLabel);
