import React, { FC, memo, useCallback, useMemo, useState } from 'react'
import { Modal as ReactModal } from 'react-responsive-modal'
import { ModalSize } from '@OlimpDev/lib/dist/types'
import cn from 'classnames'
import { ReactComponent as CloseIcon } from 'assets/images/x-icon.svg'

import './Modal.scss'

export interface IModalProps {
    id: string
    size?: ModalSize
    className?: string
    handler?: React.ReactElement
    handlerClickProp?: string
    title?: string | React.ReactNode
    onChange: (visibility: boolean) => void
    open: boolean
    children?: React.ReactNode
}

const Modal: FC<IModalProps> = ({
    id,
    size = 'medium',
    className,
    children,
    handler,
    handlerClickProp,
    title,
    open,
    onChange,
}) => {
    const handleOpen = useCallback(() => {
        onChange(true)
    }, [onChange])

    const handleClose = useCallback(() => {
        onChange(false)
    }, [onChange])

    const dialogHandler = useMemo(
        () => handler && React.cloneElement(handler, { [handlerClickProp || 'onClick']: handleOpen }),
        [handler, handlerClickProp, handleOpen],
    )

    return (
        <>
            {dialogHandler}
            <ReactModal
                modalId={id}
                open={open}
                onClose={handleClose}
                center
                showCloseIcon={false}
                classNames={{
                    modalContainer: 'modal-container',
                    modalAnimationIn: 'olimpModalIn',
                    modalAnimationOut: 'olimpModalOut',
                    modal: cn('modal-box', className, size),
                }}
            >
                <button className="icon-close" onClick={handleClose}>
                    <CloseIcon />
                </button>
                {title && <div className="modal-header">{title}</div>}
                <div className="modal-content">{children}</div>
            </ReactModal>
        </>
    )
}

const Uncontrolled: FC<Omit<Omit<IModalProps, 'onChange'>, 'open'>> = (props) => {
    const [open, setOpen] = useState<boolean>(false)
    return <Modal {...props} onChange={setOpen} open={open} />
}

export const UncontrolledModal = memo(Uncontrolled)

export default memo(Modal)
