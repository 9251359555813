import React, { FC, memo, useRef, useState } from 'react'
import * as Yup from 'yup'
import { ModalRegistry } from '@OlimpDev/lib/dist/common/modals'
import { ModalPartialProps, Modals } from '@OlimpDev/lib/dist/types'
import { useFormik } from 'formik'
import { FeedbackPayload } from '@OlimpDev/lib/dist/api/user'
import './FeedbackModal.scss'
import { toast } from 'react-toastify'
import CancelDeleteButtons from '../../../components/UI/CancelDeleteButtons'
import PhoneInput from '../../../components/UI/PhoneInput'
import { Iti } from 'intl-tel-input'
import InputLabel from '../../../components/UI/InputLabel'
import TextareaLabel from '../../../components/UI/TextareaLabel'
import { URLs } from '../../../urls'

interface FeedbackForm {
    name: string | undefined
    phone: string | undefined
    email: string | undefined
    message: string
}

interface FeedbackModalProps {
    name?: string
    email?: string
    phone?: string
    onFeedback?: (msg: string) => Promise<void> | void
}
export const sendFeedback = async (payload: FeedbackPayload): Promise<Response> => {
    return fetch(`${URLs.apiHostName}/api/v2/support`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
    })
}

const FeedbackFormSchema = Yup.object().shape({
    name: Yup.string().required('Your name is required'),
    phone: Yup.string().required('Phone is required'),
    email: Yup.string().email('Invalid email').required('Required'),
    message: Yup.string().required('Message is required'),
})

const Index: FC<FeedbackModalProps> = ({ onFeedback, email, phone, name }) => {
    const [errorMessage, setErrorMessage] = useState<string>('')
    const [validateOnChange, setValidateOnChange] = useState<boolean>(false)

    const mobileItiRef = useRef<Iti | null>(null)

    const formik = useFormik<FeedbackForm>({
        initialValues: {
            name: name ?? '',
            phone: phone ?? '',
            email: email ?? '',
            message: '',
        },
        validateOnMount: false,
        validateOnChange: validateOnChange,
        validationSchema: FeedbackFormSchema,
        enableReinitialize: true,
        onSubmit: async (values) => {
            setErrorMessage('')

            try {
                formik.setSubmitting(true)
                const response = await sendFeedback(values as FeedbackPayload)
                if (!response.ok) {
                    // get json response and throw
                    throw await response.json()
                }
                formik.setSubmitting(false)
                ModalRegistry.get().close(Modals.FeedbackModal)
                toast('Your message has been successfully sent to our team. We will contact you shortly.', {
                    type: 'success',
                })
            } catch (errors: any) {
                formik.setSubmitting(false)
                if (errors) {
                    setErrorMessage(errors?.data?.message ?? 'An error occurred')
                }
            }
        },
    })

    return (
        <div>
            <form onSubmit={formik.handleSubmit}>
                <div className="modal-feedback-inputs">
                    <InputLabel
                        name="name"
                        className="sign-up__input sign-up__input_p"
                        value={formik.values.name}
                        errorMessage={formik.errors.name}
                        onChange={formik.handleChange}
                        label="Name*"
                    />
                    <PhoneInput
                        itiRef={mobileItiRef}
                        id="user-phone"
                        label="Phone number*"
                        name="phone"
                        className="sign-up__input_phone"
                        value={formik.values.phone}
                        errorMessage={formik.touched.phone && formik.errors.phone ? formik.errors.phone : undefined}
                        changeValue={(newValue) => formik.setFieldValue('phone', newValue)}
                        autoComplete="tel"
                    />

                    <InputLabel
                        name="email"
                        disabled={!!email}
                        className="sign-up__input sign-up__input_p"
                        value={formik.values.email}
                        errorMessage={formik.errors.email}
                        onChange={formik.handleChange}
                        label="E-mail*"
                    />
                    <TextareaLabel
                        value={formik.values.message}
                        onChange={formik.handleChange}
                        placeholder="Message"
                        label="How can we help?"
                        name="message"
                        errorMessage={formik.errors.message}
                    />
                </div>

                <CancelDeleteButtons
                    handleCancel={() => ModalRegistry.get().close(Modals.FeedbackModal)}
                    isSubmitting={formik.isSubmitting}
                    disableSubmit={!formik.isValid}
                    errorMessage={errorMessage}
                    wrapperClassName="modal-feedback__actions"
                    textSubmit="Send"
                    stylesSubmit={{ width: '80px' }}
                    buttonsPosition="right"
                    onSubmitClick={() => {
                        setValidateOnChange(true)
                    }}
                />
            </form>
        </div>
    )
}

ModalRegistry.get().register<FeedbackModalProps>(Modals.FeedbackModal, {
    id: 'FeedbackModalProps',
    className: 'modal-feedback',
    size: 'medium',
    Component: memo(Index),
    title: 'Contact support',
})

export const showFeedbackModal = (props: ModalPartialProps<FeedbackModalProps>): void =>
    ModalRegistry.get().show<FeedbackModalProps>(Modals.FeedbackModal, props)

export default memo(Index)
