import React, { useState } from 'react'
import Button from 'components/UI/Button'
import { handleSubscribe } from './index'
import { showFeedbackModal } from './FeedbackModal'
import { EmailPreferencesResponse } from './interfaces'

interface IUnsubscribeFormProps {
    email: EmailPreferencesResponse
    token: string | null
    onCancel: () => void
    onSuccess: () => void
    type: string
}

const UnsubscribeForm: React.FC<IUnsubscribeFormProps> = ({ email, token, onCancel, onSuccess, type }) => {
    const [isSubmitting, setIsSubmitting] = useState(false)
    let preferences = {
        chatEmailOptOut: true,
        marketingEmailOptOut: true,
    }
    if (type === 'Supply') {
        preferences = Object.assign(preferences, { warehousingEmailOptOut: true })
    }
    const handleUnsubscribe = async () => {
        await handleSubscribe(
            token,
            preferences,
            'You have successfully unsubscribed',
            'Failed to unsubscribe',
            'Error while unsubscribing',
            onSuccess,
            setIsSubmitting,
        )
    }

    return (
        <>
            <div className="sign-up__row">
                <div>
                    Are you sure you want to unsubscribe from all email communication for{' '}
                    <span className="font-semibold">{email?.email}</span>?
                </div>
            </div>
            <div className="sign-up__row">
                If you wish to resubscribe, you can do so by going to your account and changing your Notification
                Settings or reaching out to us.
            </div>
            <div className="sign-up__row">
                <Button className="button bordered full mr10" onClick={onCancel}>
                    Cancel
                </Button>
                <Button className="button blue full ml10" onClick={handleUnsubscribe} disabled={isSubmitting}>
                    {isSubmitting ? 'Unsubscribing...' : 'Unsubscribe'}
                </Button>
            </div>
            <div className="unsubscribe-form__help">
                <a
                    href="#"
                    onClick={(e) => {
                        e.preventDefault()
                        showFeedbackModal({
                            props: {
                                email: email.email,
                                phone: email.phone,
                                name: email.fullName,
                            },
                        })
                    }}
                >
                    Questions? We’re happy to help
                </a>
            </div>
        </>
    )
}

export default UnsubscribeForm
