import React from 'react'
import { handleSubscribe } from './index'
import { showFeedbackModal } from './FeedbackModal'
import { EmailPreferencesResponse } from './interfaces'

interface IUnsubscribeSuccessProps {
    email: EmailPreferencesResponse
    onResubscribe: () => void
    token: string | null
    type: string
}

const UnsubscribeSuccess: React.FC<IUnsubscribeSuccessProps> = ({ email, onResubscribe, token, type }) => {
    let preferences = {
        chatEmailOptOut: false,
        marketingEmailOptOut: false,
    }
    if (type === 'Supply') {
        preferences = Object.assign(preferences, { warehousingEmailOptOut: false })
    }
    const handleResubscribe = async () => {
        await handleSubscribe(
            token,
            preferences,
            'You have resubscribed to email communication',
            'Failed to resubscribe',
            'Error while resubscribing',
            onResubscribe,
        )
    }

    return (
        <div className="unsubscribe-success">
            <div className="sign-up__row">
                <div>
                    <strong>{email?.email}</strong> has been successfully unsubscribed from email communication.
                </div>
            </div>
            <div className="sign-up__row">
                <a href="#" onClick={handleResubscribe}>
                    Mistake? Resubscribe to email communication
                </a>
            </div>
            <div className="sign-up__row">
                <a
                    href="#"
                    onClick={() =>
                        showFeedbackModal({
                            props: {
                                email: email.email,
                                name: email.fullName,
                                phone: email.phone,
                            },
                        })
                    }
                >
                    Questions? We’re happy to help
                </a>
            </div>
        </div>
    )
}

export default UnsubscribeSuccess
