import React, { FC } from 'react'
import Button from 'components/UI/Button'
import cn from 'classnames'

interface CancelDeleteButtonsProps {
    isEditableMode?: boolean
    handleCancel: () => void
    handleSubmit?: () => void
    isSubmitting: boolean
    errorMessage?: string
    disableSubmit?: boolean
    wrapperClassName?: string
    textSubmit?: string
    stylesSubmit?: Record<string, string>
    buttonsPosition?: 'left' | 'right'
    onSubmitClick?: () => void
}

const CancelDeleteButtons: FC<CancelDeleteButtonsProps> = ({
    isEditableMode = true,
    handleCancel,
    handleSubmit,
    isSubmitting,
    errorMessage,
    disableSubmit,
    wrapperClassName,
    textSubmit = 'Save Changes',
    stylesSubmit = { width: '151px' },
    buttonsPosition = 'left',
    onSubmitClick,
}) => {
    if (!isEditableMode) return null

    const buttonWrapperClasses = cn(wrapperClassName, 'card-CD-btns', {
        'card-CD-btns_left': buttonsPosition === 'left',
        'card-CD-btns_right': buttonsPosition === 'right',
    })

    const errorClass = cn('card-error-message', {
        'card-error-message_left': buttonsPosition === 'left',
        'card-error-message_right': buttonsPosition === 'right',
    })

    return (
        <div className={`${cn(wrapperClassName)}`}>
            <div className={buttonWrapperClasses}>
                <Button
                    className="card-cancell"
                    type="button"
                    types={['bordered']}
                    label="Cancel"
                    onClick={handleCancel}
                />
                <Button
                    className="card-delete"
                    types={['blue']}
                    type="submit"
                    label={textSubmit}
                    onClick={() => {
                        if (handleSubmit) {
                            handleSubmit()
                        }

                        if (onSubmitClick) {
                            onSubmitClick()
                        }
                    }}
                    loading={isSubmitting}
                    disabled={disableSubmit}
                    style={stylesSubmit}
                />
            </div>
            {errorMessage && <div className={errorClass}>{errorMessage}</div>}
        </div>
    )
}

export default CancelDeleteButtons
