import { useLocation } from 'react-router-dom'
import { Helmet } from 'react-helmet'

export const CanonicalHelmet = ({ title = 'Single Sign On' }) => {
    const location = useLocation()
    const canonicalUrl = `https://auth.olimpwarehousing.com${location.pathname}`

    return (
        <Helmet>
            <title>OLIMP - {title}</title>
            <link rel="canonical" href={canonicalUrl} />
        </Helmet>
    )
}
